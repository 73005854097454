import React from "react";
import logo from "./assets/images/jobbing.png";
import logo2 from "./assets/images/logo2.png";
import phone from "./assets/images/phone.png";
import automotriz from "./assets/images/automotriz.png";
import estiloDeVida from "./assets/images/estiloDeVida.png";
import hogar from "./assets/images/hogar.png";
import it from "./assets/images/it.png";
import legales from "./assets/images/legales.png";
import mascotas from "./assets/images/mascotas.png";
import medicos from "./assets/images/medicos.png";

import "./App.css";

function App() {
  const { innerHeight: height } = window;

  return (
    <>
      <div id={"container"} style={{ height: height }}>
        <header>
          <nav>
            <img
              onClick={() => {
                window.location.href = "https://jobbing.mx";
              }}
              id={"logo"}
              src={logo}
              width={145}
              height={50}
              alt="logo"
            />
            <div id={"navigation"}>
              <p className={"menuItem"}>¿Cómo funciona?</p>
              <p className={"menuItem"}>¿Porqué Jobbing?</p>
              <p className={"menuItem"}>Aviso de privacidad</p>
            </div>
            <button id={"btnDownload"}>Descargar</button>
          </nav>
        </header>
        <main>
          <p id="title">
            Bienvenido a la <b>red de trabajo</b> más grande de México
          </p>
          <img id={"phone"} src={phone} width={410} height={825} alt="phone" />
        </main>
      </div>
      <div id={"container2"} style={{ height: height / 4 }}>
        <p id={"monetizaTrabajo"}>
          Monetiza <span>tu trabajo</span>
        </p>
        <div id={"descriptionContainer"}>
          <p id={"description"}>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor.
          </p>
          <p id={"descriptionLink"}>Obtener la aplicación</p>
        </div>
      </div>
      <div id={"container3"} style={{ height: height }}>
        <div id={"container3Title"}>
          Encuentra servicios y <span>gente de confianza</span>
        </div>
        <div id={"container3Description"}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis, nascetur ridiculus mus.
        </div>
        <div id={"services"}>
          <div id={"serviceItem"}>
            <img src={mascotas} width={50} height={55} alt="logo" />
            <p>Mascotas</p>
          </div>
          <div id={"serviceItem"}>
            <img src={hogar} width={55} height={55} alt="logo" />
            <p>Hogar</p>
          </div>
          <div id={"serviceItem"}>
            <img src={estiloDeVida} width={50} height={55} alt="logo" />
            <p>Estilo de vida</p>
          </div>
          <div id={"serviceItem"}>
            <img src={it} width={55} height={55} alt="logo" />
            <p>IT y diseño</p>
          </div>
          <div id={"serviceItem"}>
            <img src={legales} width={55} height={55} alt="logo" />
            <p>Legales</p>
          </div>
          <div id={"serviceItem"}>
            <img src={medicos} width={55} height={55} alt="logo" />
            <p>Médicos</p>
          </div>
          <div id={"serviceItem"}>
            <img src={automotriz} width={55} height={55} alt="logo" />
            <p>Automotriz</p>
          </div>
          <div id={"serviceItem"}>
            <p>Explorar todo</p>
          </div>
        </div>
      </div>
      <footer>
        <img
          onClick={() => {
            window.location.href = "https://jobbing.mx";
          }}
          id={"logo2"}
          src={logo2}
          width={130}
          height={170}
          alt="logo"
        />
        <p>
          Oportunidades de trabajo y contratación de servicios{" "}
          <b>¡todo en una misma app!</b>
        </p>
        <div>
          <a href="https://jobbing.mx/privacy-notice.html">Centro de ayuda</a>
          <a href="https://jobbing.mx/privacy-notice.html">
            Aviso de Privacidad
          </a>
          <a href="https://jobbing.mx/privacy-notice.html">
            Condiciones de Términos y Usos
          </a>
          <a href="https://jobbing.mx/privacy-notice.html">Cookies</a>
        </div>
      </footer>
    </>
  );
}

export default App;
